@use 'styles/mixins';
@use 'styles/variables';

.carouselItem {
  cursor: pointer;
}

.collectionName {
  position: absolute;
  margin: 1.5rem;
  font-size: 2rem;
  line-height: 2.64rem;
  letter-spacing: 0.05rem;
  @include mixins.headlineTypography;
  color: #ffffff;

  @include mixins.media {
    font-size: 2.7rem;
    line-height: 3.8rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.generalName {
  position: absolute;
  margin: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.76rem;
  letter-spacing: 0.05rem;
  @include mixins.headlineTypography;
  color: #ffffff;

  @include mixins.media {
    font-size: 1.6rem;
    line-height: 1.76rem;
  }
}

.generalBody {
  position: absolute;
  margin-top: 2.3rem;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0.05rem;
  line-height: 1.32rem;
  @include mixins.bodyTypography;
  color: #ffffff;

  @include mixins.media {
    font-size: 1.2rem;
    line-height: 1.32rem;
  }
}

.attributeLabel {
  @include mixins.bodyTypography;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  width: 100%;
  font-size: 1rem;
  padding: 0.2rem;
  border-radius: 0 0 0.2rem 0.2rem;
  @include mixins.media {
    font-size: 1.4rem;
    padding: 0.4rem;
  }
}

.collectionHeadline {
  position: absolute;
  inset: 8rem 1.5rem 1.5rem 1.5rem;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0.05rem;
  line-height: 1.32rem;
  @include mixins.bodyTypography;
  color: #ffffff;

  @include mixins.media {
    font-size: 1.2rem;
    line-height: 1.32rem;
    inset: 10.5rem 2.8rem 0rem 2.8rem;
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  height: 16.4rem;
  border-radius: 0.2rem 0.2rem 0 0;
  background: var(--image-background-color);
  // variables.$keune-white;
  transition: background-color 0.2s ease-out;
  @include mixins.media {
    height: 34rem;
  }
  img {
    transform: scale(1);
    transition: transform 0.2s ease-out;
  }

  &.productAnimation {
    //background: variables.$keune-lightgrey;

    img {
      animation: boomerang 0.3s ease-in-out;
      transform: scale(1.1);
    }
  }

  @keyframes boomerang {
    0% {
      transform: scale(1);
    }

    //70% {
    //  transform: scale(1.2);
    //}

    100% {
      transform: scale(1.1);
    }
  }

  .image {
    position: relative;
    height: 100%;
    width: 100%;
  }
}

.bottomRounded {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.productInfo {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  position: relative;
}

.wishlistIcon {
  position: absolute;
  top: -1rem;
  right: 0;
  cursor: pointer;
}

.productName {
  font-size: 1.2rem;
  line-height: 1.5rem;
  width: 100%;
  min-height: 4.5rem;
  @include mixins.headlineTypography;
  letter-spacing: 0;
  font-weight: bold;
  @include mixins.media {
    font-size: 1.6rem;
    line-height: 2rem;
    min-height: 4rem;
  }
}

.hoveredName {
  position: absolute;
  white-space: nowrap;
  top: 14.4rem;
  @include mixins.titleTypography;
  color: #ffffff;
  font-size: 5.2rem;
  animation: scroll var(--totalDuration) infinite linear;

  @keyframes scroll {
    from {
      transform: translateX(30%);
    }
    to {
      transform: translateX(-150%);
    }
  }
}

.productSize {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  height: 2rem;
  @include mixins.media {
    font-size: 1.4rem;
    height: 2.8rem;
  }
}

.withRightPadding {
  padding-right: 4rem;
}

@mixin price {
  @include mixins.headlineTypography;
  letter-spacing: 0;
  font-size: 1.2rem;
  @include mixins.media {
    font-size: 1.6rem;
  }
}

.prices {
  margin-top: 0.7rem;
  @include mixins.media {
    margin-top: 1rem;
  }
}

.productPrice {
  @include price;
}

.salesPrice {
  @include price;
  color: variables.$keune-red;
  margin-left: 0.6rem;
  @include mixins.media {
    margin-left: 1rem;
  }
}

.productTag {
  @include mixins.productTag;
}

.outOfStockTag {
  border-radius: 0.16rem;
  @include mixins.productTag;
  background-color: variables.$keune-black;
  width: fit-content;
  @include mixins.media {
    width: fit-content;
  }
}

.salesTag {
  @include mixins.productTag;
  background-color: variables.$keune-dark-red;
  width: fit-content;
  display: flex;
  @include mixins.media {
    width: fit-content;
  }
}

.productTagsRow {
  @include mixins.productTagsRow;
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  @include mixins.media {
    top: 2rem;
    left: 2rem;
  }
}

.evesStarsPlaceholder {
  height: 1.25rem;
  margin-top: 0.5rem;
  @include mixins.media {
    height: 1.7rem;
    margin-top: 0.75rem;
  }
}
