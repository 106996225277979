@use 'styles/mixins';
@use 'styles/variables';

.highlightsSection {
  scroll-margin-top: 15rem;
  position: relative;
  height: 50%;
  margin: 3.5rem 0;
  @include mixins.media {
    margin: 4rem 0;
  }
}

.title {
  float: left;
  margin: 0.3rem 0 0.15rem 1.7rem;
  font-size: 1.6rem;
  line-height: 1.76rem;
  @include mixins.titleTypography;
  @include mixins.media {
    font-size: 2.4rem;
    margin-left: 6rem;
    margin-bottom: 0;
  }
}

.generalTitle {
  text-align: center;
  font-size: 2rem;

  @include mixins.titleTypography;
  @include mixins.media {
    width: 50rem;
    margin: 0 auto;
    font-size: 3.6rem;
    line-height: 3.96rem;
  }
}

.nextButton {
  // Relative to the carousel wrapper
  left: 137.6rem;
  top: 50%;
  position: absolute;
  z-index: 100;
  @include mixins.roundButton;
}

.previousButton {
  top: 50%;
  left: 2.8rem;
  position: absolute;
  z-index: 100;
  @include mixins.roundButton;
}

.linkToMore {
  float: right;
  margin-right: 2.17rem;
  font-size: 1.4rem;
  text-decoration: underline;
  @include mixins.headlineTypography;
  @include mixins.media {
    margin-top: 0.7rem;
    margin-right: 6rem;
  }
}

.carouselWrapper {
  width: 100vw;
  max-width: 100%;
  overflow: auto hidden;
  scrollbar-width: none;
  @include mixins.media {
    overflow: hidden;
  }
  z-index: 2;
}

.carouselWrapper::-webkit-scrollbar {
  display: none;
}

.carousel {
  display: flex;
  margin-top: 0.5rem;
  width: fit-content;
  z-index: 1;
  @include mixins.media {
    margin-top: 2rem;
    min-width: calc(100% - 6rem);
  }
}
.centeredCarousel {
  @include mixins.media {
    justify-content: center;
  }
}

@mixin carouselItem {
  position: relative;
  cursor: pointer;
  scroll-snap-align: center;
  @include mixins.media {
    width: 31.5rem !important;
  }
}

.productHighlight {
  @include carouselItem;
  width: 15.6rem;
  min-height: 25rem;
  user-select: none;
  margin-right: 1.5rem;
  @include mixins.media {
    margin-right: 2rem;
    min-height: 43.6rem;
  }
  &:first-of-type {
    margin-left: 1.7rem;
    @include mixins.media {
      margin-left: 6rem;
    }
  }
}

.categoryHighlight {
  @include carouselItem;
  height: 32.8rem;
  width: 25rem;
  margin-right: 1.5rem;
  @include mixins.media {
    margin-right: 2rem;
    height: 42rem;
  }

  > div {
    height: 100%;
  }
  &:first-of-type {
    margin-left: 1.7rem;
    @include mixins.media {
      margin-left: 6rem;
    }
  }
}
.generalHighlight {
  @include carouselItem;
  width: 25rem;
  height: 14.8rem;
  border-radius: 0.2rem;
  margin-top: 0.8rem;
  @include mixins.media {
    width: 31.5rem;
    height: 20rem;
  }
  > div {
    height: 100%;
  }
  &:first-of-type {
    margin-left: 1.6rem;
    @include mixins.media {
      margin-left: 6rem;
    }
  }
  &:not(:last-of-type) {
    margin-right: 1.5rem;
    @include mixins.media {
      margin-right: 2rem;
    }
  }
}

.disabledLinks {
  pointer-events: none;
}
