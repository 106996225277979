@use 'styles/mixins';
@use 'styles/variables';

.container {
  height: 1.25rem;
  margin-top: .5rem;
  @include mixins.media {
    height: 1.7rem;
    margin-top: .75rem;
  }

  .starIcon {
    height: 1.5rem;
    width: 1.5rem;

    @include mixins.media {
      height: 2rem;
      width: 2rem;
    }
  }

  .averageRatingContainer {
    display: flex;
    align-items: center;

    .averageRating {
      @include mixins.headlineTypography;
      font-size: 1.25rem;
      letter-spacing: 0;
      margin-right: 0.6rem;
      margin-bottom: 0.4rem;

      @include mixins.media {
        font-size: 1.6rem;
      }
    }

    .totalCount {
      font-size: 1.25rem;
      @include mixins.headlineTypography;
      letter-spacing: 0;
      margin-left: 0.2rem;
      margin-bottom: 0.4rem;
      @include mixins.media {
        font-size: 1.6rem;
      }
    }
  }
}

