@use 'styles/mixins';
@use 'styles/variables';

.addToCartButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  gap: 0.3rem;

  color: variables.$keune-black;
  border-radius: variables.$button-border-radius;
  background: none;
  border: 1px solid variables.$keune-lightgrey;
  font-size: 1.2rem;

  &:hover {
    background: variables.$keune-black;
    color: #ffffff;

    .cartIcon {
      fill: #ffffff;
    }
  }

  &:disabled {
    background: none;
    cursor: default;
    color: variables.$keune-black-50-percent;
  }

  @include mixins.media {
    gap: 0.5rem;
    font-size: 1.4rem;
  }
}

.cartSection {
  display: flex;
  margin-top: 0.5rem;
  @include mixins.media {
    width: 100%;
    margin-top: 0.75rem;
  }
}

.cartIcon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  margin-bottom: 2px;

  @include mixins.media {
    margin-left: 0;
    margin-right: 1rem;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.salesPrice {
  color: variables.$keune-red;
}

.productPrice {
  font-size: 1.125rem;
  @include mixins.headlineTypography;
  letter-spacing: 0;
  color: inherit;

  @include mixins.media {
    font-size: inherit;
  }
}
